//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EntityThumbnail from '@/components/EntityThumbnail.vue'
export default {
    name: 'GroupMicroView',
    components:{ EntityThumbnail },
    props: ['group'],
    data(){
        return {
            image:''
        }
    },
    methods:{
         fetchThumbnails(id){
            this.payload = {
                id: id
            }
            this.$store.dispatch('group/getCoverPhoto',this.payload)
            .then((r) => {
               r.status == 'success' ?  this.image = r.image: ''
            })
        }
    },
    created(){
        console.log(this.group)
        this.fetchThumbnails(this.group.entity_id)
    }
}
